import { inject } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map, catchError, of } from 'rxjs';
import { SamlLoginRequestLoginTypeEnum } from '@finboot/ng-identity-management';
import { login } from '../services/authentication.service';

export const samlAuthGuard: CanActivateFn = (route) => {
    const router = inject(Router);
    const identifier = route.queryParams['identifier'];
    const credentials = route.queryParams['credentials'];

    if (!identifier || !credentials) {
        return true; // Let the component handle normal login flow
    }

    return fromPromise(
        login({
            loginType: SamlLoginRequestLoginTypeEnum.Saml,
            identifier,
            credentials,
        }),
    ).pipe(
        map(() => true),
        catchError(() => {
            router.navigate(['/login'], {
                queryParams: { error: true },
                queryParamsHandling: 'merge',
            });
            return of(false);
        }),
    );
};
